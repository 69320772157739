import React, { useEffect } from "react";
import "./App.css";
import "./tailwind.generated.css";
import PandaPat from "./Assets/patcattransparent.png";
import PandaPat2 from "./Assets/PandaPatEating2.png";
import Logo from "./Assets/Logo2.png";
import Steven from "./Assets/Steven2.png";
import Neil from "./Assets/Neil2.png";
import BookBaby from "./Assets/bookbaby-coupons.png";
import Apple from "./Assets/Apple-Books.png";
import Monster from "./Assets/SMA17_Approved_Seal_web.png";
import FiveStars from "./Assets/5star-shiny-hr.png";
import SmallColoringPage from "./Assets/activity_coloring_page_small.png";
import SmallWordSearch from "./Assets/activity_word_search_small.png";
import SmallCrossword from "./Assets/activity_crossword_small.png";

// @ts-ignore
import ShopifyBuy from "@shopify/buy-button-js";
import { HiExternalLink } from "react-icons/hi";

// import {ReactComponent as Apple} from './Assets/AppleBadge.svg'

function App() {
  useEffect(() => {
    const client = ShopifyBuy.buildClient({
      domain: "panda-pat.myshopify.com",
      storefrontAccessToken: "46c784a56bb9779e3d8ca4653c0651ea",
    });
    const ui = ShopifyBuy.UI.init(client);
    ui.createComponent("product", {
      id: "6240057196733",
      node: document.getElementById("product-component-1613975211905"),
      moneyFormat: "%24%7B%7Bamount%7D%7D",
      options: {
        product: {
          styles: {
            product: {
              "@media (min-width: 601px)": {
                "max-width": "calc(25% - 20px)",
                "margin-left": "20px",
                "margin-bottom": "50px",
              },
            },
            title: {
              "font-family": "Geneva, sans-serif",
              "font-weight": "normal",
            },
            button: {
              "font-family": "Geneva, sans-serif",
              ":hover": {
                "background-color": "#d38a15",
              },
              "background-color": "#ea9917",
              ":focus": {
                "background-color": "#d38a15",
              },
            },
            price: {
              "font-family": "Geneva, sans-serif",
            },
            compareAt: {
              "font-family": "Geneva, sans-serif",
            },
            unitPrice: {
              "font-family": "Geneva, sans-serif",
            },
          },
          buttonDestination: "modal",
          contents: {
            img: false,
            title: false,
            price: false,
            options: false,
          },
          text: {
            button: "View product",
          },
        },
        productSet: {
          styles: {
            products: {
              "@media (min-width: 601px)": {
                "margin-left": "-20px",
              },
            },
          },
        },
        modalProduct: {
          contents: {
            img: false,
            imgWithCarousel: true,
            button: false,
            buttonWithQuantity: true,
          },
          styles: {
            product: {
              "@media (min-width: 601px)": {
                "max-width": "100%",
                "margin-left": "0px",
                "margin-bottom": "0px",
              },
            },
            button: {
              "font-family": "Geneva, sans-serif",
              ":hover": {
                "background-color": "#d38a15",
              },
              "background-color": "#ea9917",
              ":focus": {
                "background-color": "#d38a15",
              },
            },
            title: {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "bold",
              "font-size": "26px",
              color: "#4c4c4c",
            },
            price: {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "18px",
              color: "#4c4c4c",
            },
            compareAt: {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              color: "#4c4c4c",
            },
            unitPrice: {
              "font-family": "Helvetica Neue, sans-serif",
              "font-weight": "normal",
              "font-size": "15.299999999999999px",
              color: "#4c4c4c",
            },
          },
          text: {
            button: "Add to cart",
          },
        },
        option: {},
        cart: {
          styles: {
            button: {
              "font-family": "Geneva, sans-serif",
              ":hover": {
                "background-color": "#d38a15",
              },
              "background-color": "#ea9917",
              ":focus": {
                "background-color": "#d38a15",
              },
            },
          },
          text: {
            total: "Subtotal",
            button: "Checkout",
          },
          popup: true,
        },
        toggle: {
          styles: {
            toggle: {
              "font-family": "Geneva, sans-serif",
              "background-color": "#ea9917",
              ":hover": {
                "background-color": "#d38a15",
              },
              ":focus": {
                "background-color": "#d38a15",
              },
            },
          },
        },
      },
    });
  }, []);

  return (
    <div className="App">
      <div className="flex flex-1 overflow-hidden min-w-full justify-center items-center flex-col pb-4 text-gray-800">
        <div className="flex object-center  items-center justify-center relative md:w-192 w-full sm:h-full h-96">
          <img
            src={PandaPat}
            className="relative bg-contain md:h-144 w-144 sm:w-full"
            alt="Panda Pat Header"
          />
          <a href="https://readersfavorite.com/book-review/panda-pat-and-the-rat-called-cat">
            <img
              className="absolute lg:h-48 lg:w-48 md:w-40 md:h-40 h-32 w-32 left-0 bottom-0 md:ml-12 md:mb-12 sm:ml-16 sm:mb-16 ml-8 mb-8 transform ease-in-out hover:scale-110 duration-500"
              src={FiveStars}
              alt="Readers' Favorite Approved!"
            />
          </a>
          <a href="https://www.storymonsters.com/book-briefs">
            <img
              className="absolute lg:h-48 lg:w-48 md:w-40 md:h-40 h-32 w-32 right-0 bottom-0 md:mr-12 md:mb-12 sm:mr-16 sm:mb-16 mr-8 mb-8 transform ease-in-out hover:scale-110 duration-500"
              src={Monster}
              alt="Story Monsters Approved!"
            />
          </a>
        </div>
        <span className="text-2xl font-bold">Now Available!</span>

        <div className="text-lg font-bold">Paperback - $9.99</div>
        <div className="flex lg:flex-row flex-col justify-center items-center pb-5 lg:space-y-0 space-y-2 space-x-0 lg:space-x-4 justify-center items-center">
          <div id="product-component-1613975211905" />
        </div>

        <div className="text-lg font-bold">
          Hardcover - $14.99 <br />
          (with coupon code "PandaPat")
        </div>
        <div className="py-4">
          <a
            className="flex justify-center items-center bg-white w-36 h-12 font-bold rounded-lg shadow-lg py-2 px-4 transform ease-in-out hover:scale-110 duration-500 border"
            href="https://store.bookbaby.com/bookshop/book/index.aspx?bookURL=Panda-Pat-and-the-Rat-Called-Cat&amp;b=c_bu-bu-or"
          >
            <img src={BookBaby} alt="BookBaby" />
          </a>
        </div>

        <div className="space-y-3">
          <div className="text-lg font-bold">iBooks - $2.99</div>
          <a
            className="flex justify-center items-center overflow-hidden bg-black w-36 h-12 font-bold rounded-lg shadow-lg transform ease-in-out hover:scale-110 duration-500 border border-gray-500"
            href="https://books.apple.com/us/book/panda-pat-and-the-rat-called-cat/id1541901342?ign-gact=1"
          >
            <img src={Apple} alt="Apple" />
          </a>
        </div>

        {/*<div*/}
        {/*    className='flex lg:flex-row flex-col justify-center items-center lg:space-y-0 space-y-2 space-x-0 lg:space-x-4 justify-center items-center'>*/}
        {/*    /!*<span className='text-2xl font-bold'>Now Available</span>*!/*/}

        {/*    <a className='flex justify-center items-center bg-white w-36 h-12 font-bold rounded-lg shadow-lg py-2 px-4 transform ease-in-out hover:scale-110 duration-500 border'*/}
        {/*       href='https://www.amazon.com/Panda-Pat-Rat-Called-Cat/dp/1098335783/'><img src={Amazon}*/}
        {/*                                                                                  alt='Amazon'/></a>*/}
        {/*    <a className='flex justify-center items-center bg-white w-36 h-12 font-bold rounded-lg shadow-lg py-2 px-4 transform ease-in-out hover:scale-110 duration-500 border'*/}
        {/*       href='https://www.target.com/p/panda-pat-and-the-rat-called-cat-by-neil-ballard-hardcover/-/A-81941783'><img*/}
        {/*        src={Target} alt='Target'/></a>*/}
        {/*    <a className='flex justify-center items-center bg-white w-36 h-12 font-bold rounded-lg shadow-lg py-2 px-4 transform ease-in-out hover:scale-110 duration-500 border'*/}
        {/*       href='https://store.bookbaby.com/bookshop/book/index.aspx?bookURL=Panda-Pat-and-the-Rat-Called-Cat&amp;b=c_bu-bu-or'><img src={BookBaby}*/}
        {/*                                                                                    alt='BookBaby'/></a>*/}
        {/*    <a className='flex justify-center items-center bg-white w-36 h-12 font-bold rounded-lg shadow-lg py-2 px-4 transform ease-in-out hover:scale-110 duration-500 border'*/}
        {/*       href='https://www.barnesandnoble.com/w/panda-pat-and-the-rat-called-cat-neil-ballard/1138022086'><img*/}
        {/*        src={BarnesAndNobles} alt='Barnes & Nobles'/></a>*/}
        {/*</div>*/}
      </div>
      <div className="flex flex-1 overflow-hidden min-w-full justify-center bg-secondary py-6 px-6 text-light inner-shadow-custom">
        <div className="max-w-screen-xl flex flex-col space-y-6">
          <div className="flex lg:flex-row flex-col flex-1 lg:space-x-6 flex-col-reverse">
            <div className="flex flex-col lg:flex-1 text-xl font-bold space-y-3 justify-center items-center lg:items-end pt-3">
              <img className="object-cover" src={PandaPat2} alt="" />
            </div>
            <div className="flex flex-col flex-1 text-xl font-bold space-y-3 justify-center">
              <h1 className="text-4xl font-bold">
                <img src={Logo} alt="Panda Pat and the Rat called Cat" />
              </h1>
              <p className="font-bold text-center">
                Winner of "2020 Story Monsters Approved" for School Life
                Category
              </p>
              <p className="text-center lg:text-left">
                What would you do if the tricks you played caused you to lose
                all your friends? The rat called Cat and his friend Panda Pat
                travel from Panda Peak to the big city to get cheese but end up
                in the middle of a road race. Cat is determined to win the race,
                but in the whirlwind of competition, things go awry. In this
                story of redemption, Cat learns how his words have affected
                others and how to treat others with humility and respect.
              </p>

              <p className="text-center lg:text-left">
                Lifelong educator Neil Ballard has created this endearing story
                that captures hearts and teaches a valuable lesson. A manuscript
                of this short story was kept in Neil’s desk for over 25 years.
                His hope was that someday he would make his first children’s
                book from this story. Upon his second retirement from teaching
                and with the encouragement of his children, he did just that.
                Artwork by Steven Nosov brings the story to life with clever,
                vivid illustrations that can be appreciated by children and
                parents alike.
              </p>
              <span>Genre: Children’s</span>
              <span>Ages 4-8</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 overflow-hidden min-w-full justify-center bg-primary inner-shadow-custom  py-6 px-6">
        <div className="max-w-screen-xl flex flex-col space-y-2">
          <h2 className="text-4xl font-bold text-gray-800">
            What are people saying?
          </h2>
          <h4 className="text-md font-bold text-gray-800">
            Reviewed by Amy Louise Hill for Readers' Favorite
          </h4>
          <span className="text-xl font-bold text-gray-800">
            Panda Pat and the Rat Called Cat was a pleasure to read. Children
            are going to love this beautifully-written story by the talented
            Neil Ballard. The message behind the story is heart-warming and will
            teach children many lessons, but mainly about being kind and
            thinking before you do something, as every action can have
            consequences. The illustrations by Steven Nosov are highly
            professional and will attract the attention of any young reader. I
            recommend this book to anyone with children and I wish both Neil and
            Steven all the best. I hope to see them work together again in the
            future.
          </span>
        </div>
      </div>

      <div className="flex flex-1 overflow-hidden min-w-full justify-center bg-white inner-shadow-custom  py-6 px-6">
        <div className="max-w-screen-xl flex flex-col space-y-2 justify-center">
          <h2 className="text-4xl font-bold text-gray-800">
            Activities for Kids
          </h2>
          <div className="flex flex-col lg:flex-row space-x-6">
            <div className="flex justify-center">
              <a
                className="space-y-2"
                href="/assets/Panda%20Pat%20Coloring%20Page.pdf"
              >
                <img
                  className="object-cover object-top rounded-lg w-64 h-64 shadow-lg"
                  src={SmallColoringPage}
                  alt="Coloring Page"
                />
                <h4 className="text-md font-bold text-gray-800 flex flex-row space-x-1 justify-center items-center">
                  <span>Downloadable Coloring Pages</span>
                  <HiExternalLink />
                </h4>
              </a>
            </div>

            <div className="flex flex-row">
              <div className="flex justify-center">
                <a
                  className="space-y-2"
                  href="/assets/Panda%20Pat%20Word%20Search.pdf"
                >
                  <img
                    className="object-cover object-top rounded-lg w-64 h-64 shadow-lg"
                    src={SmallWordSearch}
                    alt="Word Search"
                  />
                  <h4 className="text-md font-bold text-gray-800 flex flex-row space-x-1 justify-center items-center">
                    <span> Downloadable Word Search</span>
                    <HiExternalLink />
                  </h4>
                </a>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex justify-center">
                <a
                  className="space-y-2"
                  href="/assets/Panda%20Pat%20Crossword.pdf"
                >
                  <img
                    className="object-cover object-top rounded-lg w-64 h-64 shadow-lg"
                    src={SmallCrossword}
                    alt="Crossword"
                  />
                  <h4 className="text-md font-bold text-gray-800 flex flex-row space-x-1 justify-center items-center">
                    <span>Downloadable Crossword</span>
                    <HiExternalLink />
                  </h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-1 overflow-hidden min-w-full items-center justify-center bg-background py-6 px-6 inner-shadow-custom">
        <div className="max-w-screen-xl items-start flex flex-1 flex-col space-y-6 p-3">
          <div className="grid lg:space-x-12 space-x-0 lg:space-y-0 space-y-12 lg:grid-cols-2 grid-cols-1 text-gray-700">
            <div className="flex justify-start items-center flex-col">
              <div className="w-72 h-72 rounded-full shadow-lg overflow-hidden flex justify-between items-center">
                <img className="" src={Neil} alt="Neil Ballard" />
              </div>
              <span className="text-4xl font-bold text-gray-800">Author</span>
              <span className="text-2xl font-bold text-gray-800">
                Neil Ballard
              </span>
              <p className="text-xl font-bold">
                Neil Ballard is a retired teacher from Idaho. He draws
                inspiration for his stories from his experience in the
                classroom. His background includes a wide variety of experiences
                from his early days as a football coach until his last years
                teaching at a performing art school. He has a B.A. in Education
                from University of Kentucky and M. Ed. from Middle Tennessee
                State University.
              </p>
            </div>

            <div className="flex justify-start items-center flex-col">
              <div className="w-72 h-72 rounded-full shadow-lg overflow-hidden flex justify-between items-center">
                <img className="" src={Steven} alt="Steven Nosov" />
              </div>
              {/*<img className='w-72 h-72 rounded-full shadow-lg'*/}
              {/*     src='https://www.liveabout.com/thmb/YgeC4R-s1aqPXJ1ZumRo8f-I8m0=/957x600/filters:no_upscale():max_bytes(150000):strip_icc()/carrot-nose-599c461c03f40200117e8cf8.jpg' alt=''/>*/}
              <span className="text-4xl font-bold text-gray-800">
                Illustrator
              </span>
              <span className="text-2xl font-bold text-gray-800">
                Steven Nosov
              </span>
              <p className="text-xl font-bold">
                Steven Nosov was born and raised in the Pacific Northwest. Since
                Steven was old enough to hold a pencil, he has been drawing and
                putting his imagination to paper. He’s fulfilled his life-long
                dream of being a professional illustrator. Steven studies and
                draws inspiration from cartoons and video game design to bring
                his illustrations a unique and playful style. “Panda Pat and the
                Rat Called Cat” is the first children’s book he has illustrated.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 overflow-hidden min-w-full max-h-96 justify-center bg-primary inner-shadow-custom">
        {/*<div className='max-w-screen-xl'>*/}
        <div className="max-w-screen-xl items-start justify-center flex flex-1 flex-row p-3">
          <a
            className="h-full text-md text-gray-800 font-bold flex items-center justify-start transition duration-300 ease-in-out hover:text-gray-600 px-3"
            target="_top"
            href="mailto:neil@pandapat.com"
          >
            Contact Us
          </a>
          <p className="h-full text-sm text-gray-800 font-bold flex flex-1 items-center justify-end px-3">
            ©{" "}
            {Array.from(new Set([2020, new Date().getFullYear()])).join(" - ")}{" "}
            Panda Pat
          </p>
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
}

export default App;
